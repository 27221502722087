import React from "react";
import styled, { keyframes } from "styled-components";
import { COLORS } from "../utils/colors";
import { motion } from "framer-motion";
import UserContext from "../context/userContext";
import GlobalStateContext from "../context/globalStateContext";
import {
  PowerSettingsNew as PowerIcon,
  Menu as MenuIcon,
} from "@mui/icons-material";
import DiamondIcon from "@mui/icons-material/Diamond";
import HamburgerOptionsModal from "./HamburgerOptionsModal";
import { APP_NAME } from "../utils/constants";
import * as amplitude from "@amplitude/analytics-browser";

const fadeIn = keyframes`
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
`;

const Container = styled.div`
  background-color: ${COLORS.niceDeepBlack};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${COLORS.niceWhite};
  padding: 2rem;
  position: relative;
  overflow: hidden;
`;

const PowerIconWrapper = styled.div`
  cursor: pointer;
  color: ${COLORS.niceWhite};
  opacity: 0.8;
  transition: all 0.3s ease;

  &:hover {
    opacity: 1;
    transform: scale(1.1);
  }
`;

const GradientButton = styled.button`
  background: linear-gradient(90deg, ${COLORS.nicePurple}, ${COLORS.nicePink});
  color: ${COLORS.niceWhite};
  border: none;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 4px;
  animation: ${fadeIn} 0.8s ease-out 0.6s both;

  &:hover {
    opacity: 0.9;
    transform: translateY(-3px);
    box-shadow: 0 4px 8px rgba(188, 144, 207, 0.2);
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(227, 132, 163, 0.4);
  }
`;

const GradientText = styled.span`
  background: linear-gradient(45deg, ${COLORS.nicePurple}, ${COLORS.nicePink});
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
  font-size: ${(props) => props.fontSize || "inherit"};
  margin-bottom: ${(props) => props.marginBottom || "0"};
  margin-top: ${(props) => props.marginTop || "0"};
  opacity: 0.8;
    font-weight: ${(props) => props.fontWeight || "inherit"};
  text-align: "center"
  animation: ${fadeIn} 0.8s ease-out 0.4s both;
`;

const Header = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 1rem 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${COLORS.niceDeepBlack};
  z-index: 100;
  gap: ${(props) => (props.isMobile ? "1rem" : "0")};
`;

const HeaderActions = styled.div`
  display: flex;
  align-items: center;
  gap: 2rem;
  width: ${(props) => (props.isMobile ? "100%" : "auto")};
  justify-content: ${(props) => (props.isMobile ? "center" : "flex-end")};
`;

const PricingLink = styled(motion.span)`
  color: ${COLORS.niceWhite};
  cursor: pointer;
  position: relative;
  font-weight: 500;
  animation: ${fadeIn} 0.8s ease-out 0.6s both;

  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: -4px;
    left: 0;
    background-color: ${COLORS.nicePink};
    transform: scaleX(0);
    transform-origin: bottom right;
    transition: transform 0.3s ease-out;
  }

  &:hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }
`;

const HamburgerIcon = styled(MenuIcon)`
  cursor: pointer;
  color: ${COLORS.niceWhite};
  transition: all 0.3s ease;

  &:hover {
    opacity: 0.8;
  }
`;

const HeaderLogo = styled(PricingLink)`
  font-weight: 700;
`;

const AnimatedDiamondIcon = styled(DiamondIcon)`
  animation: ${fadeIn} 0.8s ease-out 0.4s both; // Changed to match text timing (0.4s)
`;

const SignupButton = styled(GradientButton)`
  background: linear-gradient(45deg, ${COLORS.nicePink}, ${COLORS.niceBlack});
`;

const LogoutButton = styled(GradientButton)``;

const MainHeader = ({ setLogoutModalOpen, isMobile }) => {
  const { user, setUser } = React.useContext(UserContext);
  const { globalState, setGlobalState } = React.useContext(GlobalStateContext);

  const handleAddPractice = () => {
    amplitude.track("Add Practice Clicked");
    if (!user) {
      setGlobalState({ showSignupModal: true });
      return;
    }

    setGlobalState({ showAddPracticeModal: true });
  };

  const handleHamburgerClick = () => {
    setGlobalState({ showHamburgerOptions: true });
  };

  const handleLogoutClick = () => {
    setLogoutModalOpen(true);
  };

  return (
    <Container>
      <HamburgerOptionsModal setLogoutModalOpen={setLogoutModalOpen} />

      {/* ======== HEADER ======== */}
      <Header isMobile={isMobile}>
        <div style={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
          <AnimatedDiamondIcon
            sx={{
              fontSize: isMobile ? 24 : 32,
              color: COLORS.nicePink,
            }}
          />
          <HeaderLogo
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={() => (window.location.href = "/")}
            isMobile={isMobile}
          >
            <GradientText fontSize="1.25rem">{APP_NAME}</GradientText>
          </HeaderLogo>
        </div>
        <HeaderActions>
          {isMobile ? (
            <HamburgerIcon onClick={handleHamburgerClick} />
          ) : (
            <>
              <SignupButton onClick={handleAddPractice}>
                Add Your Practice
              </SignupButton>
              {user && (
                <PowerIconWrapper onClick={handleLogoutClick}>
                  <PowerIcon />
                </PowerIconWrapper>
              )}
            </>
          )}
        </HeaderActions>
      </Header>
    </Container>
  );
};

export default MainHeader;
