import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { motion, AnimatePresence } from 'framer-motion';
import styled from 'styled-components';
import {COLORS} from "../utils/colors"
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { keyframes } from 'styled-components';

const fadeIn = keyframes`
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
`;

const FAQSectionContainer = styled(motion.section)`
  width: 100%;
  max-width: 800px;
  margin: 0 auto; // Remove top and bottom margin
  padding: 5rem 1rem; // Add vertical padding
  box-sizing: border-box;
`;

const FAQTitle = styled.h2`
  font-size: clamp(2rem, 5vw, 3rem);
  text-align: center;
  margin-bottom: 3rem;
`;

const StyledAccordion = styled(Accordion)`
  background-color: transparent !important;
  color: ${COLORS.niceWhite} !important;
  border: 1px solid ${COLORS.nicePurple};
  margin-bottom: 1rem !important;
  transition: all 0.3s ease !important;
  overflow: hidden;
  width: 100%; // Ensure full width

  &:hover {
    box-shadow: 0 0 15px ${COLORS.nicePurple}60;
  }

  &.Mui-expanded {
    margin-bottom: 1rem !important;
    box-shadow: 0 0 20px ${COLORS.nicePurple}80;
  }

  &::before {
    display: none;
  }
`;


const StyledAccordionSummary = styled(AccordionSummary)`
  padding: 0 1rem; // Add padding to the summary

  .MuiAccordionSummary-content {
    margin: 1rem 0 !important;
  }

  .MuiSvgIcon-root {
    color: ${COLORS.nicePink};
    transition: transform 0.3s ease;
  }

  &.Mui-expanded .MuiSvgIcon-root {
    transform: rotate(180deg);
  }
`;

const AccordionQuestion = styled.h3`
  font-size: 1.2rem;
  font-weight: 600;
  transition: color 0.3s ease;

  &:hover {
    color: ${COLORS.nicePink};
  }
`;

const AccordionAnswer = styled(motion.div)`
  font-size: 1rem;
  line-height: 1.6;
  opacity: 0.8;
`;


const faqVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        staggerChildren: 0.2,
        delayChildren: 0.3,
      },
    },
  };

  const faqItemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };

  const GradientText = styled.span`
  background: linear-gradient(45deg, ${COLORS.nicePurple}, ${COLORS.nicePink});
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
  font-size: ${(props) => props.fontSize || "inherit"};
  margin-bottom: ${(props) => props.marginBottom || "0"};
  margin-top: ${(props) => props.marginTop || "0"};
  opacity: 0.8;
    font-weight: ${(props) => props.fontWeight || "inherit"};
  text-align: "center"
  animation: ${fadeIn} 0.8s ease-out 0.4s both;
`;


const FAQSection = ({ FAQuestions, expandedPanel, handleAccordionChange }) => {
  return (
    <FAQSectionContainer initial="hidden" animate="visible" variants={faqVariants}>
      <FAQTitle>
        <GradientText>Frequently Asked Questions</GradientText>
      </FAQTitle>

      {FAQuestions.map((item, index) => (
        <motion.div key={index} variants={faqItemVariants}>
          <StyledAccordion
            expanded={expandedPanel === `panel${index}`}
            onChange={handleAccordionChange(`panel${index}`)}
          >
            <StyledAccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel${index}a-content`}
              id={`panel${index}a-header`}
            >
              <AccordionQuestion>{item.question}</AccordionQuestion>
            </StyledAccordionSummary>
            <AccordionDetails>
              <AnimatePresence>
                {expandedPanel === `panel${index}` && (
                  <AccordionAnswer
                    initial={{ opacity: 0, y: -10 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -10 }}
                    transition={{ duration: 0.3 }}
                  >
                    {item.answer}
                  </AccordionAnswer>
                )}
              </AnimatePresence>
            </AccordionDetails>
          </StyledAccordion>
        </motion.div>
      ))}
    </FAQSectionContainer>
  );
};

export default FAQSection;
