import React, { useState, useEffect, useContext, useRef, useMemo } from "react";
import { COLORS } from "../utils/colors";
import styled, { keyframes, css } from "styled-components";
import GlobalStateContext from "../context/globalStateContext";
import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
  InputAdornment,
  CircularProgress,
  useMediaQuery,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import ReactPlayer from "react-player";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { motion, useAnimation, AnimatePresence } from "framer-motion";
import { useInView } from "react-intersection-observer";
import Footer from "../components/Footer";
import { Box } from "@mui/material";
import toast, { toastConfig } from "react-simple-toasts";
import "react-simple-toasts/dist/theme/light.css";
import { auth, googleProvider } from "../firebaseConfig";
import { useLocation, useNavigate } from "react-router-dom";
import {
  companyLogos,
  DOCTOR_TYPES,
  FAQuestions,
  MOST_POWERFUL_AI_MODEL,
  quotes,
  uniLogos,
  videoFeatures,
} from "../utils/constants";
import SignupModal from "../components/SignupModal";
import UserContext from "../context/userContext";
import {
  fetchUserInfo,
  generateAvatars,
  handleSubmit,
  logoutFirebase,
} from "../utils/helperMethods";
import LogoutModal from "../components/LogoutModal";
import GoogleSignInButton from "../components/GoogleSignInButton";
import MainHeader from "../components/MainHeader";
import Avatar from "react-avatar";
import DoctorCard from "../components/DoctorCard";
import AddPracticeModal from "../components/AddPracticeModal";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import * as amplitude from "@amplitude/analytics-browser";

const fadeIn = keyframes`
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
`;

const AvatarWrapper = styled.div`
  animation: ${fadeIn} 0.8s ease-out 0.8s both;
`;

const useStyles = makeStyles(() => ({
  facePile: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    marginLeft: "10px",
    marginRight: "10px",
    marginBottom: "16px",
    marginTop: "46px",
    // gap: "10px", // Add space between avatars
  },
  avatar: {
    border: "2px solid #fff",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
  },
}));

const Container = styled.div`
  background-color: ${COLORS.niceDeepBlack};
  min-height: 95vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${COLORS.niceWhite};
  padding: 2rem;
  position: relative;
  overflow: hidden;
`;

const ContentWrapper = styled.div`
  text-align: center;
  z-index: 1;
`;

const Title = styled.h1`
  font-size: clamp(2rem, 5vw, 4.5rem); // Reduced from (3rem, 10vw, 8rem)
  font-weight: 800; // Slightly reduced from 900
  letter-spacing: -0.01em; // Reduced from -0.02em
  margin-bottom: 1rem; // Increased from 0.5rem for better spacing
  line-height: 1.2; // Added for better readability
  max-width: 1200px; // Added to control maximum width
  padding: 0 1rem; // Added for mobile padding
  animation: ${fadeIn} 0.8s ease-out;
`;

const AnimatedTextField = styled(TextField)`
  animation: ${fadeIn} 0.8s ease-out 0.6s both;
`;

const Dot = styled.span`
  color: ${COLORS.nicePink};
  display: inline-block;
  animation: ${fadeIn} 0.8s ease-out 0.2s both;
`;

const GradientButton = styled.button`
  background: linear-gradient(90deg, ${COLORS.nicePurple}, ${COLORS.nicePink});
  color: ${COLORS.niceWhite};
  border: none;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 4px;
  animation: ${fadeIn} 0.8s ease-out 0.6s both;

  &:hover {
    opacity: 0.9;
    transform: translateY(-3px);
    box-shadow: 0 4px 8px rgba(188, 144, 207, 0.2);
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(227, 132, 163, 0.4);
  }
`;

const Subtitle = styled.h2`
  font-size: clamp(1rem, 2.5vw, 1.3rem); // Reduced from (1rem, 3vw, 1.5rem)
  font-weight: 400;
  color: ${COLORS.nicePurple};
  margin-bottom: 2rem;
  opacity: 0.8;
  max-width: 800px; // Added to control width
  margin-left: auto; // Center alignment
  margin-right: auto; // Center alignment
  padding: 0 1rem; // Consistent padding
  line-height: 1.6; // Better readability
  animation: ${fadeIn} 0.8s ease-out 0.4s both;
`;

const MainSection = styled.section`
  min-height: 100vh;
  background-color: ${COLORS.niceDeepBlack};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%; // Add this line
  max-width: 100vw; // Add this line
  overflow-x: hidden; // Add this line
`;

const GradientText = styled.span`
  background: linear-gradient(45deg, ${COLORS.nicePurple}, ${COLORS.nicePink});
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
  font-size: ${(props) => props.fontSize || "inherit"};
  margin-bottom: ${(props) => props.marginBottom || "0"};
  margin-top: ${(props) => props.marginTop || "0"};
  opacity: 0.8;
    font-weight: ${(props) => props.fontWeight || "inherit"};
  text-align: "center"
  animation: ${fadeIn} 0.8s ease-out 0.4s both;
`;

const QuoteSection = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 5rem 0;
  width: 100%;
  max-width: 800px;
`;

const QuoteContainer = styled(motion.div)`
  text-align: center;
`;

const QuoteText = styled(GradientText)`
  line-height: 1.4;
  margin-bottom: 1rem;
  color: ${COLORS.niceWhite};
`;

const QuoteAuthor = styled(Typography)`
  font-style: italic;
`;

const StyledFormControl = styled(FormControl)`
  margin: 2rem 0;
  min-width: 200px;
  .MuiOutlinedInput-root {
    color: ${COLORS.niceWhite};
    background-color: rgba(255, 255, 255, 0.05);

    .MuiOutlinedInput-notchedOutline {
      border-color: rgba(255, 255, 255, 0.1);
    }

    &:hover .MuiOutlinedInput-notchedOutline {
      border-color: rgba(255, 255, 255, 0.3);
    }
  }

  .MuiInputLabel-root {
    color: ${COLORS.niceWhite};
  }

  .MuiSelect-icon {
    color: ${COLORS.niceWhite};
  }
`;

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
//  loadStripe(
//   "pk_test_51QPvisIEp0gdWwV7vG0hbJ7QpBDqNuMcDKXkgPDB15jwQ0OBxSyw6ZbGX3oNB7WlKSTqjd37Vd4Ck40S7jqVlw9P00LmW8GRR7"
// );

const LandingPage = () => {
  const classes = useStyles();
  const { user, setUser } = useContext(UserContext);
  const { globalState, setGlobalState } = useContext(GlobalStateContext);
  const [mounted, setMounted] = useState(false);
  const [currentQuote, setCurrentQuote] = useState(0);
  const [showAvatars, setShowAvatars] = useState([]);
  const [expandedPanel, setExpandedPanel] = useState(false);
  const [logoutModalOpen, setLogoutModalOpen] = useState(false);
  const [search, setSearch] = useState("");
  const location = useLocation();
  const navigate = useNavigate();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const [selectedType, setSelectedType] = useState("all");
  const [doctors, setDoctors] = useState([]);
  const [lastVisible, setLastVisible] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);

  const fetchDoctors = async (lastDocId = null) => {
    try {
      setLoading(true);
      const params = new URLSearchParams();
      params.append("limit", "20");
      if (lastDocId) {
        params.append("lastDocId", lastDocId);
      }
      if (selectedType && selectedType !== "all") {
        params.append("type", selectedType);
      }

      // Add location parameters if we have them
      if (globalState.searchLocation) {
        params.append("lat", globalState.searchLocation.lat);
        params.append("lng", globalState.searchLocation.lng);
        params.append("radius", "50");
        params.append("includeDistance", "true");
      }

      const response = await fetch(
        `${
          process.env.REACT_APP_FETCH_DOCTORS_URL
        }/fetchPaginatedDoctors?${params.toString()}`
      );

      const data = await response.json();

      if (lastDocId) {
        setDoctors((prev) => [...prev, ...data.doctors]);
      } else {
        setDoctors(data.doctors);
      }

      setLastVisible(data.lastVisible);
      setHasMore(data.hasMore);
    } catch (error) {
      console.error("Error fetching doctors:", error);
      toast("Error loading doctors");
    } finally {
      setLoading(false);
    }
  };

  // Initial fetch on component mount
  useEffect(() => {
    setDoctors([]); // Clear existing doctors
    setLastVisible(null);
    setHasMore(true);
    fetchDoctors();
  }, [selectedType]);

  // setting avatars
  useEffect(() => {
    const genAvatars = generateAvatars(isMobile ? 10 : 20);
    setShowAvatars(genAvatars);
  }, []);

  useEffect(() => {
    if (user?._delegate?._key?.path?.segments[1] && !user?.email) {
      getUserProfile(user.id);
    }
  }, [user]);

  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentQuote((prev) => (prev + 1) % quotes.length);
    }, 7000);

    return () => clearInterval(timer);
  }, []);

  const getUserProfile = async (userId) => {
    let userData = await fetchUserInfo(userId);
    setUser((prevUser) => ({ ...prevUser, ...userData }));

    // if user is logged in, redirect to home
    if (userData?.id && userData?.email) {
      toast("Welcome!", {
        position: "top-right",
        duration: 1000,
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        style: {
          background: "linear-gradient(90deg, #BC90CF, #E384A3)",
          color: "#fff",
          fontWeight: 500,
        },
      });
    }
  };

  const handleLogoutConfirm = async () => {
    await logoutFirebase();
    setUser(null);
    setLogoutModalOpen(false);
  };

  const handleLogoutCancel = () => {
    setLogoutModalOpen(false);
  };

  const handleClick = async (event) => {
    event.preventDefault();

    // Check for empty search input
    if (!search.trim()) {
      toast("Please enter a location to search", {
        position: "top-right",
        duration: 3000,
        theme: "dark",
        style: {
          background: "linear-gradient(90deg, #BC90CF, #E384A3)",
          color: "#fff",
          fontWeight: 500,
        },
      });
      return;
    }

    // If user is not logged in, show signup modal
    if (!user) {
      setGlobalState({ showSignupModal: true });
      return;
    }

    // If user is not logged in, show signup modal (keeping existing behavior)
    if (!user) {
      handleSubmit(
        event,
        search,
        setLoading,
        setGlobalState,
        globalState,
        location,
        toast
      );
      return;
    }

    // If user is logged in, proceed with location search
    setLoading(true);
    try {
      const geocodeResponse = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
          search
        )}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`
      );

      const geocodeData = await geocodeResponse.json();

      if (geocodeData.results && geocodeData.results[0]) {
        const { lat, lng } = geocodeData.results[0].geometry.location;

        const params = new URLSearchParams();
        params.append("limit", "20");
        if (selectedType && selectedType !== "all") {
          params.append("type", selectedType);
        }
        params.append("lat", lat);
        params.append("lng", lng);
        params.append("radius", "50");

        const response = await fetch(
          `${
            process.env.REACT_APP_FETCH_DOCTORS_URL
          }/fetchPaginatedDoctors?${params.toString()}`
        );

        const data = await response.json();
        setDoctors(data.doctors);
        setLastVisible(data.lastVisible);
        setHasMore(data.hasMore);

        setGlobalState((prev) => ({
          ...prev,
          searchLocation: { lat, lng },
        }));
      } else {
        toast("Could not find location. Please try a different address.");
      }
    } catch (error) {
      console.error("Error:", error);
      toast("Error searching for doctors");
    } finally {
      setLoading(false);
    }
  };

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };

  return (
    <>
      <Container>
        {/* ======= MODALS ======== */}
        {globalState?.showSignupModal && (
          <SignupModal setUser={setUser} user={user} />
        )}
        {globalState?.showAddPracticeModal && (
          <Elements stripe={stripePromise}>
            <AddPracticeModal />
          </Elements>
        )}
        <LogoutModal
          user={user}
          open={logoutModalOpen}
          onClose={handleLogoutCancel}
          onConfirm={handleLogoutConfirm}
        />
        {/* ======== HEADER ======== */}
        <MainHeader
          isMobile={isMobile}
          setLogoutModalOpen={setLogoutModalOpen}
        />
        {/* ======== BANNER ======== */}
        <ContentWrapper>
          <Title>
            Find <Dot>Carnivore Friendly</Dot> Doctors
          </Title>
          <Subtitle>
            <GradientText>
              Discover 1000+ doctors knowledgeable of keto / carnivore diets
              near you
            </GradientText>
            <br />
          </Subtitle>
          <Box
            sx={{
              pt: 5,
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <>
              <form onSubmit={handleClick}>
                <AnimatedTextField
                  label="Enter your city or address"
                  placeholder="1234 Main Street, Los Angeles CA"
                  autoComplete="off"
                  type="text"
                  value={search}
                  onChange={handleSearchChange}
                  required
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Typography
                          onClick={handleClick}
                          sx={{
                            color: COLORS.niceWhite,
                            fontWeight: "bold",
                            cursor: "pointer",
                          }}
                        >
                          {loading ? (
                            <CircularProgress size={24} />
                          ) : (
                            <GradientText
                              fontWeight="900"
                              gradientColor1={COLORS.niceWhite}
                              gradientColor2={COLORS.nicePink}
                            >
                              SEARCH
                            </GradientText>
                          )}
                        </Typography>
                      </InputAdornment>
                    ),
                  }}
                  sx={{
                    width: isMobile ? "90vw" : "50vw",
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "8px",
                      backgroundColor: "rgba(255, 255, 255, 0.05)",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(255, 255, 255, 0.1)",
                    },
                    "& .MuiInputLabel-root": {
                      color: "#BBBBBB",
                    },
                    "& .MuiOutlinedInput-input": {
                      color: "#FFFFFF",
                    },
                  }}
                />
              </form>
            </>

            <Box className={classes.facePile}>
              {showAvatars &&
                showAvatars.map((avatar, index) => (
                  <AvatarWrapper key={index}>
                    <Avatar
                      key={index}
                      src={avatar}
                      size="40"
                      round={true}
                      className={classes.avatar}
                    />
                  </AvatarWrapper>
                ))}
            </Box>
            {/* TESTIMONIALS  */}
            <Box
              sx={{
                height: "20vh",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
              }}
            >
              <QuoteSection sx={{ width: isMobile ? "auto" : "50%" }}>
                <AnimatePresence mode="wait">
                  <QuoteContainer
                    key={currentQuote}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -20 }}
                    transition={{ duration: 0.5 }}
                  >
                    <QuoteText>
                      "
                      {<GradientText>{quotes[currentQuote].text}</GradientText>}
                      "
                    </QuoteText>
                    <QuoteAuthor color={COLORS.niceWhite}>
                      <GradientText style={{ fontSize: 13 }}>
                        {quotes[currentQuote].author}
                      </GradientText>
                    </QuoteAuthor>
                  </QuoteContainer>
                </AnimatePresence>
              </QuoteSection>
            </Box>
          </Box>
        </ContentWrapper>
        {/* ======== MAIN SECTION ======== */}
        <MainSection>
          <Box
            sx={{
              width: "100%",
              maxWidth: "1200px",
              margin: "0 auto",
              display: "flex",
              flexDirection: "column",
              alignItems: isMobile ? null : "center",
            }}
          >
            <StyledFormControl
              style={{
                width: isMobile ? "95%" : "45%",
                alignSelf: "center",
              }}
            >
              <InputLabel id="doctor-type-label">Doctor Type</InputLabel>
              <Select
                style={{ width: "100%" }}
                labelId="doctor-type-label"
                value={selectedType}
                label="Doctor Type"
                onChange={(e) => setSelectedType(e.target.value)}
              >
                <MenuItem value="all">All Types</MenuItem>
                {DOCTOR_TYPES &&
                  DOCTOR_TYPES.map((type) => (
                    <MenuItem key={type.id} value={type.id}>
                      {type.name}
                    </MenuItem>
                  ))}
              </Select>
            </StyledFormControl>

            {loading && doctors && doctors.length === 0 ? (
              <Box sx={{ display: "flex", justifyContent: "center", my: 4 }}>
                <CircularProgress />
              </Box>
            ) : doctors && doctors.length === 0 ? (
              <Box sx={{ textAlign: "center", my: 4 }}>
                <Typography color="white">
                  No doctors found for the selected type.
                </Typography>
              </Box>
            ) : (
              doctors &&
              doctors.map((doctor) => (
                <DoctorCard key={doctor.id} doctor={doctor} />
              ))
            )}

            {hasMore && (
              <Box sx={{ textAlign: "center", mt: 4, mb: 4 }}>
                <GradientButton
                  onClick={() => fetchDoctors(lastVisible)}
                  disabled={loading}
                >
                  {loading ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : (
                    "Load More Doctors"
                  )}
                </GradientButton>
              </Box>
            )}
          </Box>
        </MainSection>
        <Footer />
      </Container>
    </>
  );
};

export default LandingPage;
