import React, { useContext, useEffect, useState } from "react";
import {
  Typography,
  Modal,
  Box,
  TextField,
  Button,
  useTheme,
  useMediaQuery,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Chip,
} from "@mui/material";
import { getFunctions, httpsCallable } from "firebase/functions";
import GlobalStateContext from "../context/globalStateContext";
import { COLORS } from "../utils/colors";
import RedClose from "./RedClose";
import { DOCTOR_TYPES, PRICING_PLANS, US_STATES } from "../utils/constants";
import { firebase } from "../firebaseConfig"; // Update this import
import UserContext from "../context/userContext";
import { guidGenerator } from "../utils/helperMethods";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import * as amplitude from "@amplitude/analytics-browser";

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: "#FFFFFF",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#BBBBBB",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

const AddPracticeModal = () => {
  const theme = useTheme();
  const { user, setUser } = useContext(UserContext);
  const { globalState, setGlobalState } = useContext(GlobalStateContext);
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [step, setStep] = useState(0);
  // Form state
  const stripe = useStripe();
  const elements = useElements();

  const [formData, setFormData] = useState({
    doctorName: "",
    practiceName: "",
    profession: "", // e.g., "Family Medicine"
    type: "", // e.g., "family-med"
    qualifications: [],
    address: "",
    city: "",
    state: "",
    country: "united states",
    phone: "",
    email: "",
    website: "",
    hasRemote: false,
    source: "user-submitted",
  });

  const [qualificationInput, setQualificationInput] = useState(""); // New state for qualification input

  // Set default plan to annual if no plan is selected
  useEffect(() => {
    if (!formData.selectedPlan) {
      setFormData((prev) => ({
        ...prev,
        selectedPlan: "yearly",
        planDetails: PRICING_PLANS[0], // Annual plan is the first option
      }));
    }
  }, []);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleQualificationKeyDown = (event) => {
    if (event.key === "Enter" && qualificationInput.trim()) {
      event.preventDefault();
      setFormData((prev) => ({
        ...prev,
        qualifications: [
          ...prev.qualifications,
          qualificationInput.trim().toUpperCase(),
        ],
      }));
      setQualificationInput("");
    }
  };

  const handleTypeChange = (event) => {
    const selectedType = event.target.value;
    const selectedDoctor = DOCTOR_TYPES.find(
      (doctor) => doctor.id === selectedType
    );

    setFormData((prev) => ({
      ...prev,
      type: selectedType,
      profession: selectedDoctor.name,
    }));
  };

  const handleDeleteQualification = (qualificationToDelete) => {
    setFormData((prev) => ({
      ...prev,
      qualifications: prev.qualifications.filter(
        (qual) => qual !== qualificationToDelete
      ),
    }));
  };

  const showTemporaryError = (message) => {
    setErrorMessage(message);
    setTimeout(() => {
      setErrorMessage("");
    }, 3000); // 3 seconds
  };

  const checkFields = () => {
    // Validate required fields
    const requiredFields = {
      doctorName: "Please enter the doctor's name",
      practiceName: "Please enter the practice name",
      type: "Please select a doctor type",
      profession: "Please select a profession",
      address: "Please enter the full address",
      city: "Please enter the city",
      state: "Please select a state",
      phone: "Please enter a phone number",
      email: "Please enter an email address",
      website: "Please enter a website",
    };

    for (const [field, message] of Object.entries(requiredFields)) {
      if (!formData[field]) {
        showTemporaryError(message);
        setLoading(false);
        return false;
      }
    }

    // Check qualifications
    if (formData.qualifications.length === 0) {
      showTemporaryError("Please enter at least one qualification");
      setLoading(false);
      return false;
    }

    // Check remote consultations
    if (formData.hasRemote === undefined) {
      showTemporaryError("Please specify if remote consultations are offered");
      setLoading(false);
      return false;
    }

    setErrorMessage(""); // Clear error message if all checks pass
    return true;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    setLoading(true);
    // Make sure stripe is initialized
    if (!stripe || !elements) {
      showTemporaryError("Stripe has not been initialized");
      setLoading(false);
      return;
    }

    const selectedDoctor = DOCTOR_TYPES.find(
      (doctor) => doctor.id === formData.type
    );

    if (!selectedDoctor) {
      console.error("Doctor type not found:", formData.type);
      showTemporaryError("Invalid doctor type selected");
      setLoading(false);
      return;
    }

    // save to firestore
    const practiceData = {
      ...formData,
      name: `${formData.doctorName}${
        formData.practiceName ? ` - ${formData.practiceName}` : ""
      }`,
      type: formData.type,
      profession: selectedDoctor?.name || formData.profession,
      qualifications: formData.qualifications.join(", "),
      id: guidGenerator(),
      country: "united states",
      source: "user-submitted",
      externalId: Date.now(),
      city: formData.city.toLowerCase(),
      state: formData.state.toLowerCase(),
      user: user.id,
      selectedPlanId: formData.selectedPlan,
      planDetails: formData.planDetails,
    };
    delete practiceData["undefined"];

    await firebase
      .firestore()
      .collection("finished-step-1-practices")
      .doc(practiceData.id)
      .set(practiceData);

    if (step === 0) {
      if (!checkFields()) {
        setLoading(false);
        return;
      }
      amplitude.track("Practice Submitted", practiceData);
      setStep(1);
      setLoading(false);
      return;
    }

    if (step === 1) {
      if (!formData.selectedPlan || !formData.planDetails) {
        showTemporaryError("Please select a pricing plan");
        setLoading(false);
        return;
      }

      try {
        amplitude.track("Subscription Started", {
          ...practiceData,
          planDetails: formData.planDetails,
        });
        // Get payment method ID from Stripe Elements
        const { error: stripeError, paymentMethod } =
          await stripe.createPaymentMethod({
            type: "card",
            card: elements.getElement(CardElement),
          });

        if (stripeError) {
          showTemporaryError(stripeError.message);
          setLoading(false);
          return;
        }

        // Create subscription using our new endpoint
        const response = await fetch(
          "https://us-central1-carnivore-fc0a2.cloudfunctions.net/createSubscription",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              email: user.email,
              priceId: formData.planDetails.stripePriceId,
              paymentMethodId: paymentMethod.id,
            }),
          }
        );

        const result = await response.json();

        if (!result.success) {
          throw new Error(result.message || "Failed to create subscription");
        }

        // Handle successful subscription
        if (result.subscription.status === "active") {
          // Save practice data to Firestore with subscription info
          amplitude.track("Subscription Success");
          await firebase
            .firestore()
            .collection("paid-user-submitted-practices")
            .doc(practiceData.id)
            .set({
              ...practiceData,
              subscriptionId: result.subscription.id,
              subscriptionStatus: result.subscription.status,
            });

          await firebase.firestore().collection("users").doc(user.id).update({
            practiceId: practiceData.id,
            subscriptionId: result.subscription.id,
            subscriptionStatus: result.subscription.status,
          });

          amplitude.track("Practice Added to Pending", practiceData);
          // Redirect to success page or dashboard
          setGlobalState({ ...globalState, showAddPracticeModal: false });
          window.location.href = `/success?session_id=${result.subscription.id}`;
        } else if (result.clientSecret) {
          // Handle additional authentication if needed
          const { error } = await stripe.confirmCardPayment(
            result.clientSecret
          );
          if (error) {
            throw new Error(error.message);
          }
          // Payment confirmed successfully
          setGlobalState({ ...globalState, showAddPracticeModal: false });
          window.location.href = `/success?session_id=${result.subscription.id}`;
        }
      } catch (error) {
        console.error("Error creating subscription:", error);
        showTemporaryError(
          error.message || "Error creating subscription. Please try again."
        );
      }
    }

    setLoading(false);
  };

  return (
    <Modal
      open={globalState?.showAddPracticeModal}
      onClose={() =>
        setGlobalState({ ...globalState, showAddPracticeModal: false })
      }
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backdropFilter: "blur(10px)",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#1E1E1E",
          borderRadius: "16px",
          width: isMobile ? "90%" : "500px",
          height: isMobile ? "90%" : "70vh",
          padding: "32px",
          boxShadow: "0 4px 30px rgba(0,0,0,0.3)",
          position: "relative",
          overflow: "hidden",
          maxHeight: "90vh",
          overflowY: "auto",
          "&::before": {
            content: '""',
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            height: "4px",
            background: "niceBlack", // `linear-gradient(90deg, ${COLORS.nicePink}, ${COLORS.niceBlue})`,
          },
          // Custom scrollbar styling
          "&::-webkit-scrollbar": {
            width: "8px",
            background: "rgba(255, 255, 255, 0.05)",
          },
          "&::-webkit-scrollbar-track": {
            background: "rgba(255, 255, 255, 0.05)",
            borderRadius: "4px",
          },
          "&::-webkit-scrollbar-thumb": {
            background: `linear-gradient(180deg, ${COLORS.nicePink}, ${COLORS.niceBlue})`,
            borderRadius: "4px",
            "&:hover": {
              background: `linear-gradient(180deg, ${COLORS.niceBlue}, ${COLORS.nicePurple})`,
            },
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: step === 0 ? "flex-end" : "space-between",
            alignItems: "center",
          }}
        >
          {step === 1 && (
            <Button
              onClick={() => setStep(0)}
              sx={{
                minWidth: "unset",
                p: 1,
                mr: 1,
                color: "#BBBBBB",
                "&:hover": {
                  color: "#FFFFFF",
                },
              }}
            >
              ←
            </Button>
          )}
          <RedClose
            handleClose={() =>
              setGlobalState({ ...globalState, showAddPracticeModal: false })
            }
          />
        </Box>

        <Typography
          variant="h4"
          sx={{ mb: 3, fontWeight: 700, textAlign: "center", color: "#FFFFFF" }}
        >
          {step === 0 ? "Enter details" : "Choose subscription"}
        </Typography>
        {/* STEP 1 - Enter details */}
        {step == 0 && (
          <form onSubmit={handleSubmit}>
            <Box sx={{ display: "flex", gap: 2, mb: 2 }}>
              <TextField
                label="Doctor Name"
                name="doctorName"
                placeholder="John Doe"
                value={formData.doctorName}
                onChange={handleInputChange}
                required
                fullWidth
                InputProps={{
                  sx: { color: "#FFFFFF" },
                }}
                InputLabelProps={{
                  sx: { color: "#BBBBBB" },
                }}
              />

              <TextField
                label="Practice Name"
                placeholder="John Doe Primary Care"
                name="practiceName"
                value={formData.practiceName}
                onChange={handleInputChange}
                required
                fullWidth
                InputProps={{
                  sx: { color: "#FFFFFF" },
                }}
                InputLabelProps={{
                  sx: { color: "#BBBBBB" },
                }}
              />
            </Box>
            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel sx={{ color: "#BBBBBB" }}>Practice Type</InputLabel>
              <Select
                name="type"
                value={formData.type}
                onChange={handleTypeChange}
                required
                sx={{
                  color: "#FFFFFF",
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgba(255, 255, 255, 0.1)",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgba(255, 255, 255, 0.2)",
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: COLORS.niceBlue,
                  },
                  "& .MuiSelect-select": {
                    "&:focus": {
                      backgroundColor: "transparent",
                    },
                  },
                  "& .MuiMenu-paper": {
                    backgroundColor: "#1E1E1E",
                  },
                  "& .MuiMenuItem-root": {
                    "&:hover": {
                      background: `linear-gradient(45deg, ${COLORS.nicePurple}, ${COLORS.niceBlue})`,
                    },
                    "&.Mui-selected": {
                      background: `linear-gradient(45deg, ${COLORS.nicePurple}, ${COLORS.niceBlue})`,
                      "&:hover": {
                        background: `linear-gradient(45deg, ${COLORS.niceBlue}, ${COLORS.nicePurple})`,
                      },
                    },
                  },
                }}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      bgcolor: "#1E1E1E",
                      "& .MuiMenuItem-root": {
                        color: "#FFFFFF",
                        "&:hover": {
                          background: `linear-gradient(45deg, ${COLORS.nicePurple}, ${COLORS.niceBlue})`,
                        },
                        "&.Mui-selected": {
                          background: `linear-gradient(45deg, ${COLORS.nicePurple}, ${COLORS.niceBlue})`,
                          "&:hover": {
                            background: `linear-gradient(45deg, ${COLORS.niceBlue}, ${COLORS.nicePurple})`,
                          },
                        },
                      },
                    },
                  },
                }}
              >
                <MenuItem value="" disabled>
                  Select Practice Type
                </MenuItem>
                {DOCTOR_TYPES.map((type) => (
                  <MenuItem key={type.id} value={type.id}>
                    {type.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <Box sx={{ mb: 2 }}>
              <TextField
                label="Qualifications"
                placeholder="Type and press Enter (e.g., MD, DO, DC)"
                value={qualificationInput}
                onChange={(e) => setQualificationInput(e.target.value)}
                onKeyDown={handleQualificationKeyDown}
                fullWidth
                InputProps={{
                  sx: { color: "#FFFFFF" },
                }}
                InputLabelProps={{
                  sx: { color: "#BBBBBB" },
                }}
              />
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: 1,
                  mt: 1,
                  minHeight: "32px",
                }}
              >
                {formData.qualifications.map((qual, index) => (
                  <Chip
                    key={index}
                    label={qual}
                    onDelete={() => handleDeleteQualification(qual)}
                    sx={{
                      background: `linear-gradient(45deg, ${COLORS.nicePurple}, ${COLORS.niceBlue})`,
                      color: "#FFFFFF",
                      fontWeight: 500,
                      transition: "all 0.3s ease",
                      "&:hover": {
                        background: `linear-gradient(45deg, ${COLORS.niceBlue}, ${COLORS.nicePurple})`,
                        transform: "translateY(-1px)",
                        boxShadow: "0 2px 8px rgba(78, 205, 196, 0.3)",
                      },
                      "& .MuiChip-deleteIcon": {
                        color: "rgba(255, 255, 255, 0.8)",
                        transition: "all 0.2s ease",
                        "&:hover": {
                          color: "#FFFFFF",
                          transform: "scale(1.1)",
                        },
                      },
                    }}
                  />
                ))}
              </Box>
            </Box>

            <TextField
              label="Full Address"
              name="address"
              placeholder="1234 Main Street, City, State ZIP"
              value={formData.address}
              onChange={handleInputChange}
              required
              fullWidth
              sx={{ mb: 2 }}
              InputProps={{
                sx: { color: "#FFFFFF" },
              }}
              InputLabelProps={{
                sx: { color: "#BBBBBB" },
              }}
            />

            <Box sx={{ display: "flex", gap: 2, mb: 2 }}>
              <TextField
                label="City"
                name="city"
                placeholder="Boston"
                value={formData.city}
                onChange={(e) => {
                  const lowerCaseValue = e.target.value.toLowerCase();
                  handleInputChange({
                    target: { name: "city", value: lowerCaseValue },
                  });
                }}
                required
                fullWidth
                InputProps={{
                  sx: { color: "#FFFFFF" },
                }}
                InputLabelProps={{
                  sx: { color: "#BBBBBB" },
                }}
              />
              <FormControl fullWidth>
                <InputLabel sx={{ color: "#BBBBBB" }}>State</InputLabel>
                <Select
                  name="state"
                  value={formData.state}
                  onChange={handleInputChange}
                  required
                  sx={{
                    color: "#FFFFFF",
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(255, 255, 255, 0.1)",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(255, 255, 255, 0.2)",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: COLORS.niceBlue,
                    },
                    "& .MuiSelect-select": {
                      "&:focus": {
                        backgroundColor: "transparent",
                      },
                    },
                    "& .MuiMenu-paper": {
                      backgroundColor: "#1E1E1E",
                    },
                    "& .MuiMenuItem-root": {
                      "&:hover": {
                        background: `linear-gradient(45deg, ${COLORS.nicePurple}, ${COLORS.niceBlue})`,
                      },
                      "&.Mui-selected": {
                        background: `linear-gradient(45deg, ${COLORS.nicePurple}, ${COLORS.niceBlue})`,
                        "&:hover": {
                          background: `linear-gradient(45deg, ${COLORS.niceBlue}, ${COLORS.nicePurple})`,
                        },
                      },
                    },
                  }}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        bgcolor: "#1E1E1E",
                        "& .MuiMenuItem-root": {
                          color: "#FFFFFF",
                          "&:hover": {
                            background: `linear-gradient(45deg, ${COLORS.nicePurple}, ${COLORS.niceBlue})`,
                          },
                          "&.Mui-selected": {
                            background: `linear-gradient(45deg, ${COLORS.nicePurple}, ${COLORS.niceBlue})`,
                            "&:hover": {
                              background: `linear-gradient(45deg, ${COLORS.niceBlue}, ${COLORS.nicePurple})`,
                            },
                          },
                        },
                      },
                    },
                  }}
                >
                  {US_STATES.map((state) => (
                    <MenuItem key={state.value} value={state.value}>
                      {state.name} ({state.abbr})
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>

            <TextField
              label="Phone"
              name="phone"
              placeholder="(123) 456-7890"
              value={formData.phone}
              onChange={handleInputChange}
              fullWidth
              sx={{ mb: 2 }}
              InputProps={{
                sx: { color: "#FFFFFF" },
              }}
              InputLabelProps={{
                sx: { color: "#BBBBBB" },
              }}
            />

            <TextField
              label="Email"
              name="email"
              type="email"
              placeholder="doctor@clinic.com"
              value={formData.email}
              onChange={handleInputChange}
              fullWidth
              sx={{ mb: 2 }}
              InputProps={{
                sx: { color: "#FFFFFF" },
              }}
              InputLabelProps={{
                sx: { color: "#BBBBBB" },
              }}
            />

            <TextField
              label="Website"
              name="website"
              placeholder="www.clinic.com"
              value={formData.website}
              onChange={handleInputChange}
              fullWidth
              sx={{ mb: 2 }}
              InputProps={{
                sx: { color: "#FFFFFF" },
              }}
              InputLabelProps={{
                sx: { color: "#BBBBBB" },
              }}
            />

            <FormControl fullWidth sx={{ mb: 3 }}>
              <InputLabel sx={{ color: "#BBBBBB" }}>
                Offers Remote Consultations?
              </InputLabel>
              <Select
                name="hasRemote"
                value={formData.hasRemote}
                onChange={handleInputChange}
                sx={{ color: "#FFFFFF" }}
              >
                <MenuItem value={false}>No</MenuItem>
                <MenuItem value={true}>Yes</MenuItem>
              </Select>
            </FormControl>
          </form>
        )}

        {/* STEP 2 - PAYMENT */}
        {step === 1 && (
          <Box sx={{ mt: 3, width: "100%" }}>
            {/* PRICING PLANS */}
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", md: "row" },
                gap: 3,
                mb: 3,
              }}
            >
              {PRICING_PLANS.map((plan) => (
                <Box
                  key={plan.id}
                  onClick={() =>
                    setFormData((prev) => ({
                      ...prev,
                      selectedPlan: plan.id,
                      planDetails: plan, // Save the entire plan object
                    }))
                  }
                  sx={{
                    flex: { xs: "1 1 auto", md: 1 },
                    p: 3,
                    borderRadius: "12px",
                    cursor: "pointer",
                    transition: "all 0.3s ease",
                    position: "relative",
                    background: "#1E1E1E",
                    minHeight: { xs: "auto", md: "400px" },

                    // Gradient border effect using pseudo-element
                    "&::before": {
                      content: '""',
                      position: "absolute",
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      borderRadius: "12px",
                      padding: "2px", // Border width
                      background:
                        formData.selectedPlan === plan.id
                          ? `linear-gradient(45deg, ${COLORS.nicePurple}, ${COLORS.niceBlue})`
                          : "rgba(255, 255, 255, 0.1)",
                      mask: "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
                      maskComposite: "exclude",
                      WebkitMask:
                        "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
                      WebkitMaskComposite: "xor",
                      pointerEvents: "none",
                    },
                    // Content styles
                    "& > *": {
                      position: "relative",
                    },
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      color: "#FFFFFF",
                      mb: 1,
                      fontWeight: 600,
                    }}
                  >
                    {plan.name}
                  </Typography>
                  <Box sx={{ display: "flex", alignItems: "baseline", mb: 2 }}>
                    <Typography
                      variant="h4"
                      sx={{
                        color: "#FFFFFF",
                        fontWeight: 700,
                      }}
                    >
                      ${plan.price}
                    </Typography>
                    <Typography
                      sx={{
                        color: "#BBBBBB",
                        ml: 1,
                      }}
                    >
                      /{plan.interval}
                      {plan.interval === "year" && (
                        <Box
                          component="span"
                          sx={{
                            ml: 1,
                            color: COLORS.niceBlue,
                            fontSize: "0.9rem",
                            fontWeight: 500,
                          }}
                        >
                          save{" "}
                          {Math.round(
                            ((PRICING_PLANS[0].price * 12 - plan.price) /
                              (PRICING_PLANS[0].price * 12)) *
                              100
                          )}
                          %
                        </Box>
                      )}
                    </Typography>
                  </Box>
                  {plan.interval === "year" && (
                    <Typography
                      sx={{
                        color: COLORS.niceRed,
                        fontWeight: 600,
                        fontSize: "0.8rem",
                      }}
                    >
                      LIMITED-TIME LAUNCH OFFER
                    </Typography>
                  )}
                  <Box sx={{ mt: 2 }}>
                    {plan.features.map((feature, index) => (
                      <Box
                        key={index}
                        sx={{ display: "flex", alignItems: "center", mb: 1 }}
                      >
                        <Box
                          sx={{
                            width: 6,
                            height: 6,
                            borderRadius: "50%",
                            border:
                              formData.selectedPlan === plan.id
                                ? "2px solid #FFFFFF"
                                : `2px solid ${COLORS.niceBlue}`,
                            mr: 2,
                          }}
                        />
                        <Typography
                          sx={{
                            color: "#FFFFFF",
                            fontSize: "0.9rem",
                          }}
                        >
                          {feature}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                </Box>
              ))}
            </Box>
            <Typography
              variant="h6"
              sx={{
                color: "#FFFFFF",
                mb: 2,
                fontWeight: 600,
              }}
            >
              Payment Details
            </Typography>

            <Box
              sx={{
                backgroundColor: "#1E1E1E",
                p: 2,
                borderRadius: "12px",
                border: "1px solid rgba(255, 255, 255, 0.1)",
                mb: 3,
              }}
            >
              <CardElement options={CARD_ELEMENT_OPTIONS} />
            </Box>

            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mb={2}
            >
              <Typography variant="h6" sx={{ color: "#FFFFFF" }}>
                Total
              </Typography>
              <Typography variant="h6" sx={{ color: "#FFFFFF" }}>
                ${formData?.planDetails?.price}
                {formData?.planDetails?.interval === "lifetime"
                  ? " once"
                  : ` / ${formData?.planDetails?.interval}`}
              </Typography>
            </Box>
          </Box>
        )}

        <Box
          sx={{
            position: "sticky",
            bottom: 0,
            backgroundColor: "#1E1E1E",
            pt: 2,
            borderTop: "1px solid rgba(255, 255, 255, 0.1)",
          }}
        >
          {errorMessage && (
            <Typography
              sx={{
                color: "#ff4444",
                mb: 2,
                textAlign: "center",
                padding: "8px",
                // backgroundColor: "rgba(255, 68, 68, 0.1)",
                borderRadius: "4px",
                // border: "1px solid rgba(255, 68, 68, 0.2)",
                fontSize: "0.875rem",
                fontWeight: 500,
                width: "100%",
              }}
            >
              {errorMessage}
            </Typography>
          )}
          {step === 0 ? (
            <Button
              type="submit"
              variant="contained"
              onClick={handleSubmit}
              fullWidth
              disabled={loading}
              sx={{
                py: 1.5,
                fontWeight: "bold",
                color: "#FFFFFF",
                borderRadius: "8px",
                transition: "all 0.3s ease",
                background: `linear-gradient(45deg, ${COLORS.nicePink}, ${COLORS.niceBlue})`,
                "&:hover": {
                  background: `linear-gradient(45deg, ${COLORS.niceBlue}, ${COLORS.nicePurple})`,
                  transform: "translateY(-2px)",
                  boxShadow: "0 4px 20px rgba(78, 205, 196, 0.4)",
                },
              }}
            >
              {loading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                "NEXT"
              )}
            </Button>
          ) : (
            <Button
              onClick={handleSubmit}
              type="submit"
              disabled={loading}
              fullWidth
              sx={{
                py: 1.5,
                fontWeight: "bold",
                color: "#FFFFFF",
                borderRadius: "8px",
                transition: "all 0.3s ease",
                background: `linear-gradient(45deg, ${COLORS.nicePurple}, ${COLORS.niceBlue})`,
                "&:hover": {
                  background: `linear-gradient(45deg, ${COLORS.niceBlue}, ${COLORS.nicePurple})`,
                  transform: "translateY(-2px)",
                  boxShadow: "0 4px 20px rgba(78, 205, 196, 0.4)",
                },
                "&:disabled": {
                  opacity: 0.7,
                  cursor: "not-allowed",
                },
              }}
            >
              {loading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                "PAY NOW"
              )}
            </Button>
          )}
          <Box
            sx={{ mt: 2, display: "flex", justifyContent: "center", gap: 1 }}
          >
            {[0, 1].map((dot) => (
              <Box
                key={dot}
                sx={{
                  width: 8,
                  height: 8,
                  borderRadius: "50%",
                  background:
                    dot === step
                      ? `linear-gradient(45deg, ${COLORS.nicePurple}, ${COLORS.niceBlue})`
                      : "rgba(255, 255, 255, 0.2)",
                  transition: "all 0.3s ease",
                }}
              />
            ))}
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default AddPracticeModal;
