import React, { useContext } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { auth, database, firestore, googleProvider } from "../firebaseConfig";
import { COLORS } from "../utils/colors";
import toast from "react-simple-toasts";
import { Typography } from "@mui/material";
import { getRandomBrightColor, guidGenerator } from "../utils/helperMethods";
import GlobalStateContext from "../context/globalStateContext";
import * as amplitude from "@amplitude/analytics-browser";

const StyledButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background: white;
  color: ${COLORS.niceDeepBlack};
  width: ${({ fullWidth }) => (fullWidth ? "100%" : "350px")};
  max-width: ${({ fullWidth }) => (fullWidth ? "400px" : "350px")};
  height: 48px;
  margin-top: 10px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s ease, opacity 0.8s ease-out,
    transform 0.8s ease-out;
  opacity: 0;
  transform: translateY(20px);

  &:hover {
    background: #f1f1f1;
  }

  ${({ $mounted }) =>
    $mounted &&
    `
    opacity: 1;
    transform: translateY(0);
  `}
`;

const GoogleSignInButton = ({
  onClose,
  user,
  setUser,
  fullWidth,
  currentLocation,
}) => {
  const { setGlobalState } = useContext(GlobalStateContext);
  const navigate = useNavigate();
  const [mounted, setMounted] = React.useState(false);

  React.useEffect(() => {
    const timer = setTimeout(() => setMounted(true), 800); // 800ms delay
    return () => clearTimeout(timer);
  }, []);

  const handleGoogleSignIn = async () => {
    try {
      amplitude.track("Auth initiated", { method: "email" });

      const result = await auth.signInWithPopup(googleProvider);
      const user = result.user;
      if (user) {
        const userRef = firestore.collection("users").doc(user.uid);
        const userDoc = await userRef.get();
        if (userDoc.exists) {
          // User already exists, update last sign-in
          await userRef.update({
            lastSignIn: new Date().toISOString(),
          });

          // Fetch existing user data
          const userData = userDoc.data();
          setUser({ id: user.uid, ...userData });
          // close modal
          setGlobalState((prevState) => ({
            ...prevState,
            showSignupModal: false,
          }));
          // Check if current location matches KnowledgeMapPage pattern
          const isKnowledgeMapPage = /^\/[^/]+\/[^/]+$/.test(currentLocation);

          if (isKnowledgeMapPage) {
            // If on KnowledgeMapPage, stay there
            navigate(currentLocation);
          } else {
            // Otherwise, go to home
            navigate("/");
          }
        } else {
          // New user, create document
          const newUserData = {
            email: user.email,
            id: user.uid,
            createdAt: new Date().toISOString(),
            lastSignIn: new Date().toISOString(),
          };

          await userRef.set(newUserData);

          setUser({ id: user.uid, ...newUserData });
          window.location.href = "/";
        }
      }
    } catch (error) {}
  };

  return (
    <StyledButton
      onClick={handleGoogleSignIn}
      $mounted={mounted}
      fullWidth={fullWidth}
    >
      <img
        src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg"
        alt="Google logo"
        width="18"
        height="18"
      />
      <Typography sx={{ fontWeight: "bold" }}>Sign in with Google</Typography>
    </StyledButton>
  );
};

export default GoogleSignInButton;
