import React from "react";
import { Box, Typography, Link, Tooltip, Zoom } from "@mui/material";
import DiamondIcon from "@mui/icons-material/Diamond";
import { keyframes } from "@mui/system";
import styled from "styled-components";
import { APP_NAME } from "../utils/constants";
import { COLORS } from "../utils/colors";
import Avatar from "react-avatar";

const sparkle = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.2); }
  100% { transform: scale(1); }
`;

const GradientText = styled.span`
  background: linear-gradient(45deg, ${COLORS.nicePurple}, ${COLORS.nicePink});
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
  font-size: ${(props) => props.fontSize || "inherit"};
  margin-bottom: ${(props) => props.marginBottom || "0"};
`;

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <Box
      component="footer"
      sx={{
        backgroundColor: COLORS.niceDeepBlack,
        padding: "5rem",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        color: COLORS.niceWhite,
      }}
    >
      <Box
        onClick={() => (window.location.href = "/")}
        sx={{
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          mb: 2,
        }}
      >
        <Tooltip
          title="Shine bright like a diamond!"
          TransitionComponent={Zoom}
          arrow
        >
          <DiamondIcon
            sx={{
              fontSize: 36,
              color: COLORS.nicePink,
              "&:hover": {
                animation: `${sparkle} 0.5s ease-in-out`,
              },
            }}
          />
        </Tooltip>
        <Box sx={{ ml: 1, lineHeight: 1.2 }}>
          <GradientText fontSize="1.5rem">{APP_NAME}</GradientText>
        </Box>
      </Box>

      <Box sx={{ my: 2 }}>
        {["Privacy", "Terms", "Contact", "Pricing"].map((item, index) => (
          <Link
            key={item}
            href={
              item === "Pricing" || item === "Features"
                ? "/pricing"
                : "mailto:hello@carnivore.lol"
            }
            sx={{
              cursor: "pointer",
              color: COLORS.niceWhite,
              textDecoration: "none",
              mx: 1,
              "&:hover": {
                color: COLORS.nicePink,
                textDecoration: "underline",
              },
            }}
          >
            {item}
          </Link>
        ))}
      </Box>

      <Typography variant="body2" color={COLORS.niceWhite} sx={{ mb: 1 }}>
        © {currentYear} {APP_NAME}
      </Typography>

      {/* New "Created by" section */}
      {/* <Box
        sx={{
          mt: 3,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          mb: 2
        }}
      >
        <Typography variant="body2" color={COLORS.niceWhite}>
          Made by 
        </Typography>
        <img
          src={require("../assets/pacman_acres.png")}
          alt="pacman_acres"
          style={{
            width: 25,
            height: 25,
            borderRadius: "50%",
            // border: `2px solid ${COLORS.nicePink}`,
            marginRight: 3,
          }}
        />
        <Link
          href="https://twitter.com/pacman_acres"
          target="_blank"
          rel="noopener noreferrer"
          sx={{
            color: COLORS.nicePink,
            textDecoration: "none",
            "&:hover": {
              textDecoration: "underline",
            },
          }}
        >
          @pacman_acres
        </Link>
      </Box> */}

      <Typography variant="body2" color={COLORS.niceWhite}>
        <Link
          href="mailto:hello@carnivore.lol"
          sx={{ color: COLORS.nicePink, textDecoration: "none" }}
        >
          hello@carnivore.lol
        </Link>
      </Typography>
    </Box>
  );
};

export default Footer;
