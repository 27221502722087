import { Box, Typography, CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import toast from "react-simple-toasts";
import styled from "styled-components";
import { COLORS } from "../utils/colors";
import { trackCustomEvent } from "../utils/pixels";

const Container = styled.div`
  background-color: ${COLORS.niceDeepBlack};
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${COLORS.niceWhite};
`;

const GradientButton = styled.button`
  background: linear-gradient(90deg, ${COLORS.nicePurple}, ${COLORS.nicePink});
  color: ${COLORS.niceWhite};
  border: none;
  padding: 0.8rem 1.5rem;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 4px;
  margin-top: 2rem;

  &:hover {
    opacity: 0.9;
    transform: translateY(-3px);
    box-shadow: 0 4px 8px rgba(188, 144, 207, 0.2);
  }
`;

const GradientText = styled.span`
  background: linear-gradient(45deg, ${COLORS.nicePurple}, ${COLORS.nicePink});
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
  font-weight: 600;
`;

const Success = () => {
  const navigate = useNavigate();
  const query = new URLSearchParams(window.location.search);
  const sessionId = query.get("session_id");
  const [isProcessing, setIsProcessing] = useState(true);

  useEffect(() => {
    if (sessionId) {
      const confirmPayment = async () => {
        try {
          const response = await fetch(
            `https://us-central1-is-everything-toxic.cloudfunctions.net/confirmPayment?session_id=${sessionId}`
          );
          const result = await response.json();

          if (result.success) {
            trackCustomEvent("Purchase", {
              value: 100.0,
              currency: "USD",
            });
          }
        } catch (error) {
          console.error("Error confirming payment:", error);
        } finally {
          setIsProcessing(false);
        }
      };

      confirmPayment();
    } else {
      navigate("/");
    }
  }, [sessionId, navigate]);

  if (isProcessing) {
    return (
      <Container>
        <Box sx={{ textAlign: "center" }}>
          <CircularProgress size={50} sx={{ mb: 3 }} />
          <Typography variant="h6" sx={{ color: COLORS.niceWhite }}>
            Processing your submission...
          </Typography>
        </Box>
      </Container>
    );
  }

  return (
    <Container>
      <Box
        sx={{
          textAlign: "center",
          maxWidth: "600px",
          padding: "2rem",
        }}
      >
        <Typography variant="h4" sx={{ mb: 3, fontWeight: "bold" }}>
          Thank You for Your Submission!
        </Typography>

        <Typography variant="h6" sx={{ mb: 4 }}>
          Your listing is now <GradientText>pending approval</GradientText>
        </Typography>

        <Typography sx={{ mb: 3, opacity: 0.9 }}>
          Our team will review your application within 3-4 business days. You'll
          receive an email notification once your listing is approved.
        </Typography>

        <Typography sx={{ opacity: 0.8 }}>
          Have questions? Contact us at hello@carnivore.lol
        </Typography>

        <GradientButton onClick={() => navigate("/")}>
          Return to Home
        </GradientButton>
      </Box>
    </Container>
  );
};

export default Success;
