import React, { useContext } from "react";
import styled from "styled-components";
import { Modal, Box, Typography } from "@mui/material";
import { COLORS } from "../utils/colors";
import RedClose from "./RedClose";
import GlobalStateContext from "../context/globalStateContext";
import UserContext from "../context/userContext";

const OptionItem = styled.div`
  padding: 1.2rem;
  color: ${COLORS.niceWhite};
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 8px;
  margin: 0.5rem 0;
  background: rgba(255, 255, 255, 0.05);
  font-weight: 500;

  &:hover {
    background: linear-gradient(45deg, ${COLORS.nicePink}, ${COLORS.niceBlue});
    transform: translateY(-2px);
    box-shadow: 0 4px 20px rgba(78, 205, 196, 0.2);
  }
`;

const HamburgerOptionsModal = ({ setLogoutModalOpen }) => {
  const { globalState, setGlobalState } = useContext(GlobalStateContext);
  const { user } = useContext(UserContext);

  const handleClose = () => {
    setGlobalState({ ...globalState, showHamburgerOptions: false });
  };

  const handleAddPractice = () => {
    handleClose();
    if (!user) {
      setGlobalState({ showSignupModal: true });
      return;
    }
    setGlobalState({ showAddPracticeModal: true });
  };

  const handleLogout = () => {
    handleClose();
    setLogoutModalOpen(true);
  };

  return (
    <Modal
      open={globalState?.showHamburgerOptions}
      onClose={handleClose}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backdropFilter: "blur(10px)",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#1E1E1E",
          borderRadius: "16px",
          width: "90%",
          maxWidth: "400px",
          padding: "32px",
          boxShadow: "none",
          position: "relative",
          overflow: "hidden",
          outline: "none",
          "&::before": {
            content: '""',
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            height: "4px",
            background: `linear-gradient(90deg, ${COLORS.nicePink}, ${COLORS.nicePink})`,
          },
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <RedClose handleClose={handleClose} />
        </Box>

        <Typography
          variant="h5"
          sx={{
            mb: 3,
            fontWeight: 700,
            textAlign: "center",
            color: "#FFFFFF",
            background: `linear-gradient(45deg, ${COLORS.nicePink}, ${COLORS.niceBlue})`,
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
          }}
        >
          Menu Options
        </Typography>

        <OptionItem onClick={handleAddPractice}>
          <Typography sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            Add Your Practice
          </Typography>
        </OptionItem>

        {user && (
          <OptionItem onClick={handleLogout}>
            <Typography sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              Logout
            </Typography>
          </OptionItem>
        )}

        <Typography
          variant="body2"
          sx={{
            mt: 3,
            textAlign: "center",
            color: "#888888",
            fontSize: "0.8rem",
          }}
        >
          Click outside to close
        </Typography>
      </Box>
    </Modal>
  );
};

export default HamburgerOptionsModal;
