import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { CircularProgress, Container, Typography, Box } from "@mui/material";
import UserContext, { useUserContext } from "../context/userContext";
import * as amplitude from "@amplitude/analytics-browser";

const FinishSignUp = () => {
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const { setUser } = useContext(UserContext); // Use useContext here

  useEffect(() => {
    const completeSignUp = async () => {
      try {
        if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
          let email = window.localStorage.getItem("emailForSignIn");

          if (!email) {
            email = window.prompt(
              "Please provide your email for confirmation:"
            );
          }

          const result = await firebase
            .auth()
            .signInWithEmailLink(email, window.location.href);

          if (result.user) {
            // Create the user document in Firestore using the user's UID
            const userDocRef = firebase
              .firestore()
              .collection("users")
              .doc(result.user.uid);
            await userDocRef.set({
              email: result.user.email,
              createdAt: firebase.firestore.FieldValue.serverTimestamp(),
              updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
              isActive: true,
              premiumUser: {
                isActive: false,
                plan: "free",
              },
              settings: {
                theme: "light",
                notifications: true,
              },
            });
            amplitude.track("Auth success - google sign in");

            window.localStorage.removeItem("emailForSignIn");
            setUser(result.user);

            const previousLocation =
              window.localStorage.getItem("previousLocation") || "/";
            window.localStorage.removeItem("previousLocation");

            navigate(previousLocation);
          }
        } else {
          setError("Invalid sign-in link.");
          setTimeout(() => navigate("/"), 3000);
        }
      } catch (error) {
        console.error("Error during sign-in completion:", error);
        setError(`Failed to complete sign-in: ${error.message}`);
        setTimeout(() => navigate("/"), 3000);
      }
    };

    completeSignUp();
  }, [navigate, setUser]);

  // ... rest of the component remains the same ...
};

export default FinishSignUp;
