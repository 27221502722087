import React from "react";
import { FAQuestions } from "../utils/constants";
import FAQSection from "../components/FAQSection";
import styled, { keyframes, css } from "styled-components";
import { COLORS } from "../utils/colors";
import { motion } from "framer-motion";
import UserContext from "../context/userContext";
import GlobalStateContext from "../context/globalStateContext";
import { logoutFirebase } from "../utils/helperMethods";
import { Typography } from "@mui/material";
import MainHeader from "../components/MainHeader";

const fadeIn = keyframes`
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
`;

const Container = styled.div`
  background-color: ${COLORS.niceDeepBlack};
  min-height: 95vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${COLORS.niceWhite};
  padding: 2rem;
  position: relative;
  overflow: hidden;
`;

const FAQPage = () => {
  const { user, setUser } = React.useContext(UserContext);
  const { globalState, setGlobalState } = React.useContext(GlobalStateContext);
  const [expandedPanel, setExpandedPanel] = React.useState(false);
  const [logoutModalOpen, setLogoutModalOpen] = React.useState(false);

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpandedPanel(isExpanded ? panel : false);
  };

  const handleActionClick = () => {};

  const handleSignupClick = () => {
    setGlobalState({ showSignupModal: true });
  };

  const handleLogoutClick = () => {
    setLogoutModalOpen(true);
  };

  const handleLogoutConfirm = async () => {
    await logoutFirebase();
    setUser(null);
    setLogoutModalOpen(false);
  };

  const handleLogoutCancel = () => {
    setLogoutModalOpen(false);
  };

  return (
    <Container>
      {/* ======== HEADER ======== */}
      <MainHeader />
      <FAQSection
        FAQuestions={FAQuestions}
        expandedPanel={expandedPanel}
        handleAccordionChange={handleAccordionChange}
      />
    </Container>
  );
};

export default FAQPage;
