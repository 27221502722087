import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/functions";
import "firebase/compat/storage";
import "firebase/compat/database";
import "firebase/compat/auth";

const firebaseConfig = {
  apiKey: "AIzaSyA7JYeVJdDs7i78-jQUOH_MsmVjCpJmOP4",
  authDomain: "carnivore-fc0a2.firebaseapp.com",
  projectId: "carnivore-fc0a2",
  storageBucket: "carnivore-fc0a2.firebasestorage.app",
  messagingSenderId: "480821715881",
  appId: "1:480821715881:web:8692cf8b71fe76f03d5ab8",
  measurementId: "G-2C3L85R774",
  databaseURL: "https://carnivore-fc0a2-default-rtdb.firebaseio.com/",
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

const firestore = firebase.firestore();

const functions = firebase.functions();

const database = firebase.database();

const storage = firebase.storage();

const auth = firebase.auth();

const googleProvider = new firebase.auth.GoogleAuthProvider();

export {
  firebase,
  firestore,
  functions,
  database,
  storage,
  auth,
  googleProvider,
};
