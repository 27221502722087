import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ReactGA from "react-ga4";
import "./App.css";
import { GlobalStateProvider } from "./context/globalStateContext";
import { UserContextProvider } from "./context/userContext";
import "./global.css";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Success from "./pages/Success";
import Pricing from "./pages/Pricing";
import LandingPage from "./pages/LandingPage";
import { useEffect, useMemo } from "react";
import FAQPage from "./pages/FAQPage";
import FinishSignUp from "./pages/FinishSignup";
import * as amplitude from "@amplitude/analytics-browser";

const theme = createTheme();

function App() {
  useEffect(() => {
    // Initialize Google Analytics
    amplitude.init(process.env.REACT_APP_AMPLITUDE_TOKEN, {
      autocapture: true,
    });
    amplitude.track("User visitor");

    // Send initial pageview
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname + window.location.search,
    });

    // Set up listener for route changes
    const handleRouteChange = () => {
      ReactGA.send({
        hitType: "pageview",
        page: window.location.pathname + window.location.search,
      });
    };

    window.addEventListener("popstate", handleRouteChange);

    return () => {
      window.removeEventListener("popstate", handleRouteChange);
    };
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <GlobalStateProvider>
          <UserContextProvider>
            <Routes>
              <Route path="/" element={<LandingPage />} />
              <Route path="/faq" element={<FAQPage />} />
              <Route path="/pricing" element={<Pricing />} />
              <Route path="/success" element={<Success />} />
              <Route path="/finishSignUp" element={<FinishSignUp />} />

              {/* PRESET WITH A FILTER - pSEO (nomadList appproach)  */}
            </Routes>
          </UserContextProvider>
        </GlobalStateProvider>
      </Router>
    </ThemeProvider>
  );
}

export default App;
